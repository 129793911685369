import auth from './auth';
import tracking from './tracking';
import gnb from './gnb';
import goodsDetail from './goodsDetail';
import goodsCategory from './goodsCategory';
import hackle from './hackle';
import header from './header';

const rootReducer = {
  [auth.name]: auth.reducer,
  [tracking.name]: tracking.reducer,
  [gnb.name]: gnb.reducer,
  [goodsDetail.name]: goodsDetail.reducer,
  [goodsCategory.name]: goodsCategory.reducer,
  [hackle.name]: hackle.reducer,
  [header.name]: header.reducer,
};

export default rootReducer;
