import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';

type InitialState = { currentCartCount: number };

// Define the initial state using that type
const initialState: InitialState = {
  currentCartCount: 0,
};

export const headerSlice = createSlice({
  name: 'header',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentCartCount: (state, action: PayloadAction<number>) => {
      state.currentCartCount = action.payload;
    },
  },
});

export const { setCurrentCartCount } = headerSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentCartCount = (state: RootState) =>
  state.header.currentCartCount;

export default headerSlice;
